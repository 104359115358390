export const useBookCreatorFields = ({ sourcesOptions, subcategoryOptions, languageOptions }) => [
  { name: 'isShown', label: 'Shown', type: 'checkbox', defaultValue: true },
  { name: 'isFree', label: 'Is free', type: 'checkbox', defaultValue: false },
  { name: 'isPublic', label: 'Is public', type: 'checkbox', defaultValue: true },
  { name: 'image', label: 'Image', type: 'file' },
  { name: 'pdf', label: 'Pdf file', type: 'file' },
  { name: 'titleEn', label: 'English title', type: 'text' },
  { name: 'titleRu', label: 'Russian title', type: 'text' },
  { name: 'sources', label: 'Source', type: 'multiSelect', options: sourcesOptions },
  {
    name: 'bookSubcategories',
    label: 'book subcategory',
    type: 'multiSelect',
    options: subcategoryOptions,
  },
  { name: 'languageUuid', label: 'Language', type: 'singleSelect', options: languageOptions },
  { name: 'pages', label: 'Pages', type: 'text' },
  // { name: 'releaseDate', label: 'Release date', type: 'date' },
  { name: 'descriptionEn', label: 'Description english', type: 'textEditor' },
  { name: 'descriptionRu', label: 'Description russian', type: 'textEditor' },
  { name: 'chLink', label: 'Ch. Link', type: 'text' },
];
